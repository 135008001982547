<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.pageTitle }}</h1>
    </header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <data-table :fields="fields" :listType="list_type" :i18n="translations.components" :items="stateCampsAlter"
      :addItems="addItems" :toggleMe="true" :handleDeleteAction="false" :handleEditAction="false"
      :handleViewAction="false" :csvUrl="csvUrl" :pdfUrl="pdfUrl" :reportName="reportName"></data-table>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'directory-camp',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'camp_number', label: 'Camp Number', sortable: true },
        { key: 'camp_name', label: 'Camp Name', sortable: true },
        { key: 'region', label: 'Region', sortable: true },
        { key: 'area', label: 'Area', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'StateCampsExport',
    }
  },
  methods: {
    ...mapActions({
      getMemberChurches: 'campModule/getMemberChurches',
      getStateCamp: 'directory/getStateCamp',
      loadChurches: 'churchMinistry/loadChurches',
      loadStateCampsUrls: 'directory/loadStateCampsUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    loadTranslations() {
      const { tcArea, tcCampName, tcCampNumber, tcRegion } = this.translations
      this.fields[0].label = tcCampNumber
      this.fields[1].label = tcCampName
      this.fields[2].label = tcRegion
      this.fields[3].label = tcArea
      this.addItems.text = this.translations.tcAddMember || 'Add Member'
      let pageTitle = this.translations.tcStateCampIndex || 'State Camp Index'
      if (this.$route.path === '/officers/directory/country/directory-camp') {
        pageTitle = this.translations.tcCountryCampIndex || 'Country Camp Index'
      }
      this.translations.pageTitle = pageTitle
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getStateCamp(this.userStateKey),
          this.loadStateCampsUrls(this.userStateKey),
          this.getViewTranslations('geographic-areas'),
          this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches')
        ]).then((results) => {
          const componentTranslations = results[3]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
      } catch (e) {
        console.error('Error during page_load(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/stateCampsCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      stateCamps: 'directory/stateCamps',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
    pageTitle() {
      return this.$route.path.includes('/officers/directory/country/directory-camp')
        ? 'Country Camp Index'
        : 'State Camp Index'
    },
    stateCampsAlter() {
      if (!this.translations || !this.stateCamps) return false
      let stateCampItems = this.stateCamps.map((c) => {

        // Translate State Region
        const region = `tc${c.region.replace(/ /g, '')}`
        const regionText = this.translations.hasOwnProperty(region) ? this.translations[region] : c.region

        // Translate State Area
        let area = c.area
        if (c.area.includes('State Area')) {
          const areaNum = c.area.split('State Area')[1].trim()
          const stateArea = this.translations.hasOwnProperty('tcStateArea') ? this.translations['tcStateArea'] : c.area
          area = `${stateArea} ${areaNum}`
        }
        return { ...c, region: regionText, area: area }
      })

      return stateCampItems
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
